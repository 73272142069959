<template>
    <div>
        <!-- title -->
        <div class="container-fluid bg-expert-listing-header px-0 py-5 position-relative" :style="`background-image: url('${ bannerImage }')`">
            <div class="row position-relative m-0">
                <div class="col-12 py-0 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 text-white text-center">
                                <h1 class="d-lg-block d-none font-size-expert-listing-header mp-rbk mp-font-weight-medium my-5 py-2">{{ title }}</h1>
                                <h1 class="d-lg-none d-block font-size-expert-listing-header-mobile mp-rbk mp-font-weight-medium my-0 py-0">{{ title }}</h1>
                            </div>
                            <!--
                            <div class="col-12 text-white text-center" v-if="aId">
                                <span class="mp-rbk mp-font-weight-medium my-5 py-2" style="font-size: 2.5rem">{{ titleArea }}</span>
                            </div>
                            -->
                            <div class="d-lg-block d-none col-12 text-white text-center">
                                <template v-if="desc">
                                    <h2 class="d-lg-block d-none mp-rbk mp-font-weight-medium">{{ desc }}</h2>
                                    <h2 class="d-lg-none d-block mp-rbk mp-font-weight-medium pt-2" style="font-size: 1.2rem">{{ desc }}</h2>
                                </template>
                                <template v-else>
                                    <h2 class="mp-rbk mp-font-weight-medium">&nbsp;</h2>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- experts-listing -->
        <div class="container-fluid bg-lightblue py-5">
            <OfferFilter :disable-list-mode-switching=true :show-courses=true :show-filter=true  @filter-changed="filterChanged($event)"/>
        </div>

        <!-- tag cloud -->
        <TagCloud class="d-none d-md-block"/>
    </div>
</template>






<script>
    import TagCloud from "../../components/TagCloud";
    import {mixinBasics} from "../../mixins/basics";
    import {random} from "lodash";
    import OfferFilter from "../../components/OfferFilter.vue";


    export default {
        name: "CourseOverview",

        metaInfo() {
            return {
                title: this.metaTitle,
                meta: [
                    {name: `${this.metaTitle} | mergeplate.com`},
                    {title: `${this.metaTitle} | mergeplate.com`}
                ].concat(this.buildOpenGraphMetaTags(
                    `${this.metaTitle} | mergeplate.com`,
                    this.metaDesc,
                    this.$route.path
                ))
            };
        },

        components: {
            OfferFilter,
            TagCloud
        },

        mixins: [
            mixinBasics
        ],

        props: {
            categoryId: {
                required: false,
                default: undefined,
            },
            areaId: {
                required: false,
                default: undefined,
            },
            searchTerm: {
                required: false,
                default: undefined,
                type: String
            },
            // SEO meta-crap
            metaArea: {
                type: String,
                required: false
            },
            metaCategory: {
                type: String,
                required: false
            },
            metaExpert: {
                type: String,
                required: false
            },
        },

        data() {
            return {
                keyPrefix: 'course',
                showDefaultBannerTitle: undefined,
                cId: undefined,
                aId: undefined
            };
        },

        computed: {
            bannerImage() {
                try {
                    return (!this.aId && !this.cId) ?
                        this._areas[random(0, this._areas.length - 1)].media.url :
                        this.aId ?
                            this._areas.find(a => a.id === this.aId).media.url :
                            this._categories.find(c => c.id === this.cId).media.url;
                } catch (e) {
                    console.log('--> Missing media!')
                }
                return undefined;
            },

            titleCategory() {
                return this.cId ? this._categories.find(c => c.id === this.cId).title : 'Angebote';
            },

            titleArea() {
                return this.aId ? this._areas.find(a => a.id === this.aId).title : undefined;
            },

            title() {
                return `${this.titleCategory} ${this.titleArea  ? ' / ' + this.titleArea : ''}`;
            },

            desc() {
                return this.aId ? this._areas.find(a => a.id === this.aId).desc :
                        this.cId ? this._categories.find(c => c.id === this.cId).desc : undefined;
            },

            metaTitle() {
                return !this.metaCategory && !this.metaArea ? 'Angebote' : `${ this.metaCategory ? `${this.metaCategory}` : '' } ${ this.metaArea ? ` / ${this.metaArea}` : ''} `;
            },

            metaDesc() {
                return !this.metaCategory && !this.metaArea ?
                    'Experten - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit' :
                    `Experten für ${this.metaTitle} finden auf mergeplate.com`;
            }
        },

        methods: {
            filterChanged(e) {
                this.aId = e.areaId;
                this.cId = e.categoryId;
            }

        },

        created() {
            this.aId = this.areaId ? parseInt(this.areaId) : undefined;
            this.cId = this.categoryId ? parseInt(this.categoryId) : undefined;
        },

        mounted() {
            window.scrollTo(0,0);
        }

    }
</script>


<style scoped>

</style>